import React from 'react'
import SvgCryptrLogo from '../../svgs/cryptr_logo'

import {
  MobileMenuHeader,
  MobileMenuCard,
  MobileMenuWrapper,
  MobileMenuLogo,
  MobileMenuIcon,
  MobileMenuProducts,
  MobileMenuProductsItem,
  MobileMenuNavItem,
  MobileMenuLink,
  MobileMenuCallToAction,
  // MobileMenuCtaProductDiscovery,
  MobileMenuCtaEventPromotion,
  MobileMenuNavItemGrid,
} from './style'

import { getIcon } from '../flyout_menu/products_flyout_menu'
import { Link } from 'gatsby'

const MobileMenu = ({ productItems, navbarData, setMenuMobileOpen }: any) => {
  return (
    <MobileMenuWrapper>
      <MobileMenuCard>
        <div className="card-background">
          <div className="card-padding">
            <MobileMenuHeader>
              <MobileMenuLogo>
                <Link to="/">
                  <SvgCryptrLogo />
                </Link>
              </MobileMenuLogo>
              <MobileMenuIcon>
                <button onClick={() => setMenuMobileOpen(false)} type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </MobileMenuIcon>
            </MobileMenuHeader>
            <MobileMenuProducts>
              <nav>
                {productItems.map(
                  (
                    item: {
                      primary: {
                        nav_link_icon: { url: string; alt: string }
                        nav_link_title: string
                      }
                      items: { nav_link_title: string; nav_link_path: string }[]
                    },
                    index: any,
                  ) => {
                    return (
                      <MobileMenuProductsItem key={index}>
                        <Link
                          to={`${item.items.map((path) => {
                            return path.nav_link_path
                          })}`}
                        >
                          <div className="nav-link-icon">
                            {getIcon(item.primary.nav_link_title)}
                          </div>
                          <div className="nav-link-title">{item.primary.nav_link_title}</div>
                        </Link>
                      </MobileMenuProductsItem>
                    )
                  },
                )}
              </nav>
            </MobileMenuProducts>
          </div>
          <MobileMenuNavItem>
            <MobileMenuNavItemGrid>
              {/* Blog */}
              <a href="https://docs.cryptr.co/" target="_blank">
                <MobileMenuLink>Documentation</MobileMenuLink>
              </a>
              <Link to={navbarData[1].items[2].nav_link_path}>
                <MobileMenuLink>{navbarData[1].items[2].nav_link_title}</MobileMenuLink>
              </Link>
              <Link to={navbarData[1].items[3].nav_link_path}>
                <MobileMenuLink>{navbarData[1].items[3].nav_link_title}</MobileMenuLink>
              </Link>
            </MobileMenuNavItemGrid>
            <MobileMenuCallToAction>
              {/* Book a demo */}
              <MobileMenuCtaEventPromotion>
                <a href={navbarData[0].items[1].call_to_action_path}>
                  {navbarData[0].items[1].call_to_action_text}
                </a>
              </MobileMenuCtaEventPromotion>
              {/* Log in */}
              {/* <MobileMenuCtaProductDiscovery>
                <a href={navbarData[0].items[0].call_to_action_path}>
                  &nbsp;{navbarData[0].items[0].call_to_action_text}
                </a>
              </MobileMenuCtaProductDiscovery> */}
            </MobileMenuCallToAction>
          </MobileMenuNavItem>
        </div>
      </MobileMenuCard>
    </MobileMenuWrapper>
  )
}

export default MobileMenu
