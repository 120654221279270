// import { Link } from 'gatsby'
import { Link } from 'gatsby'
import React from 'react'
import { FlyoutMenuCard, FlyoutMenuItem, FlyoutMenuWrapper } from './style'

const DevelopersFlyoutMenu = ({ menuItems }: any) => {
  return (
    <FlyoutMenuWrapper>
      <FlyoutMenuCard>
        <div>
          <div className="card-background">
            <a href="https://docs.cryptr.co/" target="_blank">
              <FlyoutMenuItem>
                <p className="title">Documentation</p>
              </FlyoutMenuItem>
            </a>
            {/* Developers Hub */}
            {/* <Link to={menuItems[0].nav_link_path}>
              <FlyoutMenuItem>
                <p className="title">{menuItems[0].nav_link_title}</p>
                <p className="description">{menuItems[0].nav_link_description}</p>
              </FlyoutMenuItem>
            </Link> */}
          </div>
        </div>
      </FlyoutMenuCard>
    </FlyoutMenuWrapper>
  )
}

export default DevelopersFlyoutMenu
