import * as React from 'react'

function SvgUsersManagementIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={41} height={41} viewBox="0 0 41 41" fill="none" {...props}>
      <circle cx={20.643} cy={20.05} r={20} fill="#96E2B5" />
      <path
        d="M34.426 5.558c2.78 2.645 4.8 6.08 5.698 9.947L17.09 33.125a6.792 6.792 0 01-3.81 1.161c-3.768 0-6.587-2.818-6.587-6.586V11.44h10.954v5.223c0 .501.406.908.907.908.211 0 .62-.133 1.005-.414.108-.08.956-.746 2.236-1.753 3.126-2.459 8.828-6.944 12.63-9.846z"
        fill="#fff"
      />
      <path
        d="M35.992 32.517a.851.851 0 01-1.091.854l-18.446-5.418a.851.851 0 01-.284-1.488l17.612-13.788a.851.851 0 011.375.634l.834 19.206z"
        fill="#2DCE89"
      />
    </svg>
  )
}

export default SvgUsersManagementIcon
