/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

const Container = styled.div`
  ${tw`
    relative
    max-w-screen-xl
    mx-auto
    px-4
    sm:px-6
    lg:px-8
  `}
  .image {
    ${tw`
      px-0
      shadow-2xl
    `}
    width: fit-content;
  }
`
export default Container
