import styled from '@emotion/styled'
import tw from 'twin.macro'

export const LayoutStyle = styled.div`
  ${tw`
    bg-purple-100
    overflow-hidden
  `}
  font-family: Ubuntu;
  display: block;
  margin: 0px;
  width: 100%;
  height: 100%;
`

export const CodeStyle = styled.pre`
  ${tw`
    bg-gray-900
    text-white
    w-full
    overflow-auto
  `}
  .orange-code-color {
    ${tw`
      text-orange-500
    `}
  }
  .blue-code-color {
    ${tw`
      text-teal-500
    `}
  }
  .yellow-code-color {
    ${tw`
      text-yellow-300
    `}
  }
  .white-code-color {
    ${tw`
      text-white
    `}
  }
  .padding-left-10 {
    ${tw`
      pl-10
    `}
  }
  .padding-left-5 {
    ${tw`
      pl-5
    `}
  }
`
