import * as React from 'react'

const SvgScimIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width={41} height={41} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.252 40.702c11.046 0 20-8.954 20-20s-8.954-20-20-20c-11.045 0-20 8.954-20 20s8.955 20 20 20Z"
      fill="#02AFD7"
    />
    <path
      d="m33.98 24.75 4.041-.017c1.551-10.19-4.354-18.975-12.169-21.75l-1.115 1.896C31.52 8.54 34.936 15.666 33.98 24.75Z"
      fill="#5851FF"
    />
    <path
      d="M31.512 22.513c0-8.696-7.05-15.746-15.746-15.746-1.527 0-3.631.266-4.4.623v.001c6.256-.798 12.375 3.495 12.375 10.147 0 4.786-3.25 8.813-7.662 9.995l.001 10.724c8.552-.168 15.433-7.152 15.433-15.744Z"
      fill="#fff"
    />
    <path
      d="M14.19 23.658a6.786 6.786 0 1 0-.001-13.572 6.786 6.786 0 0 0 0 13.572Z"
      fill="#A149E2"
    />
  </svg>
)

export default SvgScimIcon
