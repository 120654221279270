import * as React from 'react'

function SvgUiEditorIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={42}
      height={42}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={21.22} cy={21.265} r={20.469} fill="#7126F2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.81 13.562V6.909l-.016-.016h-6.653v2.335H13.302V6.893H6.645l-.012.011v6.658H8.97v15.403H6.633v6.66l.01.01h6.66v-1.899H29.14v1.897h6.657l.012-.012v-6.657h-2.333V13.562h2.333zm-4.333 0v15.402H29.14v2.772H13.302v-2.77H10.97v-3.733h14.005V11.228h4.167v2.334h2.336z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgUiEditorIcon
