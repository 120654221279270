import * as React from 'react'

const SvgSsoAdminOnboardingIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg width={41} height={41} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={20.723} cy={20.15} r={20} fill="#6271DD" />
    <path
      d="M34.507 5.658c2.78 2.644 4.8 6.08 5.698 9.947l-23.033 17.62a6.792 6.792 0 0 1-3.81 1.161c-3.768 0-6.587-2.818-6.587-6.587V11.54H17.73v5.224c0 .501.406.907.908.907.21 0 .619-.133 1.003-.414a309 309 0 0 0 2.237-1.753c3.126-2.458 8.828-6.943 12.63-9.845Z"
      fill="#fff"
    />
    <path
      d="M36.072 32.617a.851.851 0 0 1-1.09.854l-18.446-5.419a.852.852 0 0 1-.285-1.487l17.612-13.788a.851.851 0 0 1 1.376.633l.833 19.207Z"
      fill="#A149E2"
    />
  </svg>
)

export default SvgSsoAdminOnboardingIcon
