import styled from '@emotion/styled'
import tw from 'twin.macro'

export const FlyoutMenuWrapper = styled.div`
  ${tw`
    absolute
    left-1/2
    transform
    -translate-x-1/2
    mt-3
    px-2
    w-screen
    max-w-xs
    sm:px-0
    lg:max-w-2xl
  `}
  &.grid-cols-3 {
    ${tw`
      left-1/2
      transform
      -translate-x-1/2
      w-screen
      max-w-xs
      lg:max-w-5xl
    `}
  }
`

export const FlyoutMenuCard = styled.div`
  ${tw`
    rounded-lg
    shadow-lg
  `}
  .div {
    ${tw`
      rounded-lg
      shadow-sm
      overflow-hidden
    `}
  }
  .grid-cols-2 {
    ${tw`
      lg:grid-cols-2
    `}
  }
  .grid-cols-3 {
    ${tw`
      lg:grid-cols-3
    `}
  }
  .card-background {
    ${tw`
      z-20
      relative
      grid
      gap-6
      bg-white
      px-5
      py-6
      sm:gap-8
      sm:p-8
    `}
  }
`

export const FlyoutMenuItem = styled.div`
  ${tw`
    -m-3
    p-3
    flex
    items-start
    space-x-4
    rounded-lg
  `}
`

export const FlyoutMenuItemSvg = styled.div`
  ${tw`
    flex-shrink-0
    flex
    items-center
    justify-center
    h-12
    w-12
    text-white
  `}
  svg {
    ${tw`
      h-10
      w-10
    `}
  }
`

export const FlyoutMenuItemText = styled.div`
  ${tw`
  space-y-1
  `}
  .title {
    ${tw`
      text-base
      leading-6
      font-medium
      text-purple-900
    `}
  }
`

export const FlyoutMenuLink = styled.div`
  ${tw`
    text-sm
    leading-5
  `}
  span {
    ${tw`
      text-gray-500
      cursor-pointer
      hover:text-gray-700
      transition
      ease-in-out
      duration-150
    `}
  }
`
