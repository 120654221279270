import * as React from 'react'

function SvgAuthenticationIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={40} height={41} viewBox="0 0 40 41" fill="none" {...props}>
      <circle cx={20} cy={20.314} r={20} fill="#02AFD7" />
      <path
        d="M2.865 30.633l17.137-10.319 10.68 16.912a19.906 19.906 0 01-10.68 3.088c-7.271 0-13.636-3.88-17.137-9.681z"
        fill="#5E72E4"
      />
      <circle cx={20.001} cy={20.314} r={15.125} fill="#02AFD7" />
      <path d="M20 35.44c-5.498 0-10.311-2.935-12.959-7.322L20 20.314V35.44z" fill="#fff" />
      <path d="M40 20.314c0-11.045-8.954-20-20-20v20h20z" fill="#5E72E4" />
      <path d="M35.125 20.314H20V5.19c8.353 0 15.125 6.772 15.125 15.125z" fill="#fff" />
      <circle cx={20} cy={20.314} r={10.313} fill="#02AFD7" />
      <circle cx={20.001} cy={20.314} r={5.375} fill="#fff" />
    </svg>
  )
}

export default SvgAuthenticationIcon
