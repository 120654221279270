import * as React from 'react'

function SvgCryptrLogo(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 2273 804" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1689.11 531.525v68.576c0 17.157-13.91 31.066-31.07 31.066s-31.07-13.909-31.07-31.066V402.016c.07-80.535 57.17-153.999 136.99-153.999 79.87 0 136.99 73.554 136.99 154.146 0 80.593-57.12 154.147-136.99 154.147-28.32 0-53.78-9.25-74.85-24.785zm0-129.362c0-55.358 37.72-92.014 74.85-92.014 37.13 0 74.86 36.656 74.86 92.014 0 55.359-37.73 92.014-74.86 92.014s-74.85-36.655-74.85-92.014z"
        fill="#32376F"
      />
      <path
        d="M972.565 331.206c0-7.742 3.024-11.972 7.535-15.266 5.72-4.176 12.912-5.792 16.133-5.792h100.597c17.16 0 31.06-13.908 31.06-31.066 0-17.157-13.9-31.065-31.06-31.065H996.233c-15.672 0-35.847 5.387-52.77 17.743-18.133 13.239-33.029 35.071-33.029 65.446v141.909c0 30.374 14.892 52.208 33.027 65.448 16.923 12.357 37.099 17.744 52.776 17.741h100.593c17.15.001 31.06-13.906 31.06-31.063.01-17.158-13.9-31.067-31.06-31.068H996.226c-3.22 0-10.409-1.614-16.127-5.789-4.508-3.291-7.534-7.522-7.534-15.269V331.206zM1255.75 353.046c0-18.084 4.52-27.176 9.46-32.038 5.03-4.956 15.93-10.86 40.1-10.86 17.15 0 31.06-13.908 31.06-31.066 0-17.157-13.91-31.065-31.06-31.065-33.38 0-62.8 8.149-83.69 28.709-20.98 20.656-28 48.545-28 76.32v172.193c0 17.157 13.9 31.065 31.06 31.065 17.16 0 31.07-13.908 31.07-31.065V353.046z"
        fill="#32376F"
      />
      <path
        d="M2192.12 353.046c0-18.084 4.52-27.176 9.46-32.038 5.04-4.956 15.93-10.86 40.1-10.86 17.16 0 31.06-13.908 31.06-31.066 0-17.157-13.9-31.065-31.06-31.065-33.38 0-62.79 8.149-83.68 28.709-20.99 20.655-28.01 48.545-28.01 76.32v172.193c0 17.157 13.91 31.065 31.06 31.065 17.16 0 31.07-13.908 31.07-31.065V353.046z"
        fill="#33CBD5"
      />
      <path
        d="M2192.12 353.046c0-18.084 4.52-27.176 9.46-32.038 5.04-4.956 15.93-10.86 40.1-10.86 17.16 0 31.06-13.908 31.06-31.066 0-17.157-13.9-31.065-31.06-31.065-33.38 0-62.79 8.149-83.68 28.709-20.99 20.655-28.01 48.545-28.01 76.32v172.193c0 17.157 13.91 31.065 31.06 31.065 17.16 0 31.07-13.908 31.07-31.065V353.046z"
        fill="#33CBD5"
      />
      <path
        d="M1599.09 250.507c15.79 6.721 23.14 24.966 16.42 40.752l-45.33 111.024c-7.75 19.222-15.5 38.442-23.26 57.659l-43.9 101.249c-7.93 15.81-16.55 31.086-25.54 42.822-4.48 5.845-9.89 11.933-16.27 16.81-6.16 4.704-15.95 10.345-28.51 10.345H1385c-17.16 0-31.07-13.909-31.07-31.066 0-17.157 13.91-31.066 31.07-31.066h40.87a56.15 56.15 0 002.29-2.811c5.41-7.058 11.96-18.2 19.32-32.885 7.72-15.382 15.55-33.055 23.18-51.167l-87.62-190.078c-7.18-15.581-.37-34.035 15.21-41.217 15.58-7.183 34.04-.374 41.22 15.207l63.28 137.285c3.42-8.481 6.63-16.435 9.65-23.915 23.3-57.793 35.18-87.255 45.94-112.534 6.72-15.786 24.97-23.135 40.75-16.414zM2033.28 198.46c0-17.157-13.91-31.065-31.07-31.065s-31.06 13.908-31.06 31.065v49.559h-30.14c-17.16 0-31.06 13.908-31.06 31.066 0 17.157 13.9 31.065 31.06 31.065h30.14l.04 215.09c0 17.157 13.91 31.066 31.07 31.066 17.15 0 31.06-13.909 31.06-31.066l-.04-215.09h35.32c17.16 0 31.07-13.908 31.07-31.065 0-17.158-13.91-31.066-31.07-31.066h-35.32V198.46z"
        fill="#32376F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.39 201.03v106.701l332.618 192.037a26.457 26.457 0 0113.229 22.912l.002 81.044c20.259 9.819 34.228 30.58 34.228 54.604 0 33.494-27.152 60.646-60.646 60.646-33.493 0-60.645-27.152-60.645-60.646 0-23.993 13.933-44.731 34.149-54.566l-.001-65.807L.39 368.83v233.672L348.076 803.24l85.38-49.294V369.025a26.458 26.458 0 0113.228-22.912l70.269-40.569a60.94 60.94 0 01-.147-4.247c0-33.494 27.153-60.646 60.646-60.646 33.494 0 60.646 27.152 60.646 60.646 0 33.493-27.152 60.645-60.646 60.645-12.661 0-24.417-3.88-34.142-10.516L486.37 384.3v339.096l209.393-120.894-.01-401.478-90.969-52.521-338.818 195.611a26.457 26.457 0 01-26.457 0l-70.195-40.526c-9.706 6.597-21.427 10.452-34.048 10.452-33.493 0-60.645-27.152-60.645-60.645 0-33.494 27.152-60.646 60.645-60.646 33.494 0 60.646 27.152 60.646 60.646 0 1.466-.052 2.92-.155 4.36l56.981 32.897L551.87 117.953 348.076.293.39 201.03z"
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={92.949}
          y1={564.905}
          x2={565.002}
          y2={0.293}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4BBFCC" />
          <stop offset={0} stopColor="#33CBD5" />
          <stop offset={1} stopColor="#C6E989" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgCryptrLogo
