import styled from '@emotion/styled'
import tw from 'twin.macro'

export const HeaderBackground = styled.div`
  ${tw`
    bg-transparent
    w-full
    relative
    z-30
  `}
`

export const HeaderMenuMobileButton = styled.div`
  ${tw`
    -mr-2
    -my-2
    md:hidden
  `}
  button {
    ${tw`
      inline-flex
      items-center
      justify-center
      p-2
      rounded-md
      text-gray-400
      hover:text-gray-500
      hover:bg-gray-100
      focus:outline-none
      focus:bg-gray-100
      focus:text-gray-500
      transition
      duration-150
      ease-in-out
    `}
  }
  svg {
    ${tw`
      h-6
      w-6
    `}
  }
`

export const HeaderWrapper = styled.div`
  ${tw`
    flex
    justify-between
    flex-row
    items-center
    py-6
    w-full
  `}
`

export const HeaderLogo = styled.div`
  ${tw`
    lg:w-0 
    lg:flex-1
  `}
  a {
    ${tw`
      flex
    `}
  }
  svg {
    ${tw`
      h-8
      w-auto
    `}
  }
`

export const HeaderNav = styled.nav`
  ${tw`
    hidden
    md:flex
    w-2/3
    justify-center
    space-x-6
  `}
`

export const HeaderLinkWrapper = styled.div`
  ${tw`
    relative
  `}
`

export const HeaderLinkStyle = styled.div`
  ${tw`
    text-gray-500
    inline-flex
    items-center
    space-x-2
    text-xs
    leading-6
    font-medium
    cursor-pointer
    hover:text-gray-900
    focus:outline-none
    focus:text-gray-900
    transition
    ease-in-out
    duration-150
  `}
  a {
    ${tw`
      hover:text-gray-900
      focus:outline-none
      focus:text-gray-900
      transition
      ease-in-out
      duration-150
    `}
  }
`

export const HeaderCallToActionWrapper = styled.div`
  ${tw`
    hidden
    md:flex
    items-center
    justify-end
    md:flex-1
    lg:w-0
    space-x-4
  `}
`
