import { Link } from 'gatsby'
import React from 'react'
import SvgCustomerExperience from '../../../svgs/icons/customer_experience_icon'
import SvgAuthenticationIcon from '../../../svgs/icons/products/authentication_icon'
import SvgEnterpriseConnections from '../../../svgs/icons/products/enterprise_ready_icon'
import SvgScimIcon from '../../../svgs/icons/products/scim_icon'
import SvgSsoAdminOnboardingIcon from '../../../svgs/icons/products/sso_admin_onboarding_icon'
import SvgUiEditor from '../../../svgs/icons/products/ui_editor_icon'
import SvgUsersManagement from '../../../svgs/icons/products/users_management_icon'

import {
  FlyoutMenuWrapper,
  FlyoutMenuCard,
  FlyoutMenuItem,
  FlyoutMenuItemSvg,
  FlyoutMenuItemText,
  FlyoutMenuLink,
} from './style'

type ItemProps = {
  primary: {
    nav_link_icon: {
      url: string
      alt: string
    }
    nav_link_title: string
  }
  items: {
    nav_link_title: string
    nav_link_path: string
  }[]
}

export const getIcon = (currentNavItem: string) => {
  switch (currentNavItem) {
    case 'Passwordless User Account':
      return <SvgAuthenticationIcon />
    case 'SSO Admin Onboarding':
      return <SvgSsoAdminOnboardingIcon />
    case 'SCIM':
      return <SvgScimIcon />
    case 'Users Management':
      return <SvgUsersManagement />
    case 'UI Editor':
      return <SvgUiEditor />
    case 'Enterprise Connections':
      return <SvgEnterpriseConnections />
    default:
      return null
  }
}

const ProductsFlyoutMenu = ({ menuItems }: any) => {
  const classGrid = (products: any) => {
    if (products.length === 3) {
      return 'grid-cols-3'
    } else {
      return 'grid-cols-2'
    }
  }
  return (
    <FlyoutMenuWrapper className={`${classGrid(menuItems)}`}>
      <FlyoutMenuCard className="card">
        <div>
          <div className={`card-background ${classGrid(menuItems)}`}>
            {menuItems.map((item: ItemProps, index: any) => {
              return (
                <Link key={index} to={item.items[0].nav_link_path}>
                  <FlyoutMenuItem>
                    <FlyoutMenuItemSvg>{getIcon(item.primary.nav_link_title)}</FlyoutMenuItemSvg>
                    <FlyoutMenuItemText>
                      <p className="title">{item.primary.nav_link_title}</p>
                      <FlyoutMenuLink>
                        <span>{item.items[0].nav_link_title}</span>
                      </FlyoutMenuLink>
                    </FlyoutMenuItemText>
                  </FlyoutMenuItem>
                </Link>
              )
            })}
          </div>
        </div>
      </FlyoutMenuCard>
    </FlyoutMenuWrapper>
  )
}

export default ProductsFlyoutMenu
