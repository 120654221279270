import { Link } from 'gatsby'
import React from 'react'
import DevelopersFlyoutMenu from '../flyout_menu/developers_flyout_menu'
import ProductsFlyoutMenu from '../flyout_menu/products_flyout_menu'
import ResourcesFlyoutMenu from '../flyout_menu/resources_flyout_menu'
import { HeaderLinkWrapper, HeaderNav, HeaderLinkStyle } from '../style'

const HeaderLink = ({
  navbarData,
  productItems,
  isMenuProductsOpen,
  isMenuDevelopersOpen,
  setCurrentMenuId,
}: any) => {
  return (
    <HeaderNav>
      <HeaderLinkWrapper onMouseEnter={() => setCurrentMenuId('PRODUCTS_MENU')}>
        <HeaderLinkStyle className="group">
          <span>{navbarData[1].items[0].nav_link_title}</span>
        </HeaderLinkStyle>
        {isMenuProductsOpen && <ProductsFlyoutMenu menuItems={productItems} />}
      </HeaderLinkWrapper>
      <HeaderLinkWrapper onMouseEnter={() => setCurrentMenuId('DEVELOPERS_MENU')}>
        <HeaderLinkStyle className="group">
          <span>{navbarData[1].items[1].nav_link_title}</span>
        </HeaderLinkStyle>
        {isMenuDevelopersOpen && <DevelopersFlyoutMenu menuItems={navbarData[4].items} />}
      </HeaderLinkWrapper>
      {/* Blog */}
      <HeaderLinkWrapper onMouseEnter={() => setCurrentMenuId(null)}>
        <HeaderLinkStyle>
          <Link to={navbarData[1].items[2].nav_link_path}>
            <span>{navbarData[1].items[2].nav_link_title}</span>
          </Link>
        </HeaderLinkStyle>
      </HeaderLinkWrapper>
      {/* Pricing */}
      <HeaderLinkWrapper onMouseEnter={() => setCurrentMenuId(null)}>
        <HeaderLinkStyle>
          <Link to={navbarData[1].items[3].nav_link_path}>
            <span>{navbarData[1].items[3].nav_link_title}</span>
          </Link>
        </HeaderLinkStyle>
      </HeaderLinkWrapper>
    </HeaderNav>
  )
}

export default HeaderLink
