import styled from '@emotion/styled'
import tw from 'twin.macro'

// Add lg:max-w-3xl to card-background when we'll have more items
export const FlyoutMenuWrapper = styled.div`
  ${tw`
    absolute
    left-1/2
    transform
    -translate-x-1/2
    mt-3
    px-2
    w-screen
    max-w-xs
    sm:px-0
  `}
`

// Add lg:grid-cols-2 to card-background when we'll have more items
export const FlyoutMenuCard = styled.div`
  ${tw`
    rounded-lg
    shadow-lg
  `}
  .div {
    ${tw`
      rounded-lg
      shadow-sm
      overflow-hidden
    `}
  }
  .card-background {
    ${tw`
      z-20
      relative
      grid
      gap-6
      bg-white
      px-5
      py-6
      sm:gap-8
      sm:p-8
    `}
  }
`

export const FlyoutMenuItem = styled.div`
  ${tw`
    -m-3
    p-3
    block
    space-y-1
    rounded-md
    hover:bg-gray-100
    transition
    ease-in-out
    duration-150
  `}
  .title {
    ${tw`
      text-base
      leading-6
      font-medium
      text-purple-900
    `}
  }
  .description {
    ${tw`
      text-sm
      leading-5
      text-gray-500
    `}
  }
`
