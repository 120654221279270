import { Link } from 'gatsby'
import React from 'react'
import Container from '../elements/container'
import SvgCryptrLogo from '../svgs/cryptr_logo'

import {
  FooterBackground,
  FooterCol,
  FooterColsGrid,
  FooterCompany,
  FooterCopyright,
  FooterGrid,
  FooterLink,
  FooterRow,
  FooterSocialLink,
  FooterSocialLinksWrapper,
  FooterWrapper,
} from './style'

const getIcon = (currentFooterItem: string) => {
  switch (currentFooterItem) {
    case 'Twitter':
      return (
        <svg aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      )
    case 'Linkedin':
      return (
        <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3.67091C2 3.22776 2.17604 2.80276 2.4894 2.4894C2.80275 2.17604 3.22776 2 3.67091 2H20.3273C20.5469 1.99964 20.7644 2.0426 20.9674 2.12643C21.1704 2.21025 21.3548 2.33328 21.5102 2.48849C21.6656 2.6437 21.7888 2.82802 21.8729 3.03092C21.9569 3.23382 22.0001 3.4513 22 3.67091V20.3273C22.0002 20.5469 21.9572 20.7645 21.8732 20.9675C21.7893 21.1705 21.6662 21.3549 21.5109 21.5103C21.3556 21.6656 21.1713 21.7889 20.9683 21.8729C20.7654 21.9569 20.5478 22.0001 20.3282 22H3.67091C3.45141 22 3.23405 21.9568 3.03127 21.8727C2.82849 21.7887 2.64425 21.6655 2.48908 21.5103C2.33391 21.355 2.21085 21.1707 2.12693 20.9679C2.04301 20.7651 1.99988 20.5477 2 20.3282V3.67091ZM9.91636 9.62546H12.6245V10.9855C13.0155 10.2036 14.0155 9.5 15.5182 9.5C18.3991 9.5 19.0818 11.0573 19.0818 13.9145V19.2073H16.1664V14.5655C16.1664 12.9382 15.7755 12.02 14.7827 12.02C13.4055 12.02 12.8327 13.01 12.8327 14.5655V19.2073H9.91636V9.62546ZM4.91636 19.0827H7.83273V9.5H4.91636V19.0818V19.0827ZM8.25 6.37455C8.2555 6.62425 8.21107 6.87254 8.11931 7.10483C8.02755 7.33713 7.89032 7.54876 7.71566 7.7273C7.54101 7.90585 7.33245 8.0477 7.10223 8.14455C6.87201 8.2414 6.62476 8.29129 6.375 8.29129C6.12524 8.29129 5.87799 8.2414 5.64777 8.14455C5.41755 8.0477 5.20899 7.90585 5.03433 7.7273C4.85968 7.54876 4.72245 7.33713 4.63069 7.10483C4.53893 6.87254 4.4945 6.62425 4.5 6.37455C4.51079 5.88441 4.71308 5.41799 5.06353 5.07517C5.41399 4.73235 5.88475 4.54038 6.375 4.54038C6.86525 4.54038 7.33601 4.73235 7.68647 5.07517C8.03692 5.41799 8.23921 5.88441 8.25 6.37455V6.37455Z"
            fill="currentColor"
          />
        </svg>
      )
    default:
      return null
  }
}

const Footer = ({ footerData }: any) => {
  const ListItems = footerData[1].links.filter(
    (x: any) => x.__typename === 'PrismicLayoutFooterLinksListItems',
  )

  // const productsFirstItem = ListItems[0].items[0]
  // const productsLastItem = ListItems[0].items[ListItems[0].items.length - 1]
  // const filteredProductsItems = [productsFirstItem, productsLastItem]

  const productsItem = ListItems[0].items

  return (
    <FooterBackground>
      <Container>
        <FooterWrapper>
          <FooterGrid>
            <FooterCompany>
              <SvgCryptrLogo />
              <p>{footerData[0].company_description}</p>
              <FooterSocialLinksWrapper>
                {footerData[1].links[0].items.map((item: any, index: any) => {
                  return (
                    <FooterSocialLink target="_blank" key={index} href={item.footer_link_url.url}>
                      <span>{item.footer_link_title}</span>
                      {getIcon(item.footer_link_title)}
                    </FooterSocialLink>
                  )
                })}
              </FooterSocialLinksWrapper>
            </FooterCompany>
            <FooterColsGrid>
              <FooterCol>
                <p>{ListItems[0].primary.column_title}</p>
                <ul>
                  {productsItem.map(
                    (
                      item: { footer_link_title: string; footer_link_path: string },
                      index: string | number,
                    ) => {
                      return (
                        <li key={index}>
                          <Link to={item.footer_link_path}>{item.footer_link_title}</Link>
                        </li>
                      )
                    },
                  )}
                </ul>
              </FooterCol>
              <FooterCol>
                <p>{ListItems[3].primary.column_title}</p>
                <ul>
                  {ListItems[3].items.map(
                    (
                      item: { footer_link_title: string; footer_link_path: string },
                      index: string | number,
                    ) => {
                      return (
                        <li key={index}>
                          <Link to={item.footer_link_path}>{item.footer_link_title}</Link>
                        </li>
                      )
                    },
                  )}
                </ul>
              </FooterCol>
            </FooterColsGrid>
          </FooterGrid>
          <FooterCopyright>
            <FooterRow>
              <p>&copy;{footerData[0].copyright}</p>
              {footerData[1].links[1].items.map((item: any, index: any) => {
                return (
                  <Link to={item.footer_link_path} key={index}>
                    <FooterLink>{item.footer_link_title}</FooterLink>
                  </Link>
                )
              })}
            </FooterRow>
          </FooterCopyright>
        </FooterWrapper>
      </Container>
    </FooterBackground>
  )
}

export default Footer
