import * as React from 'react'

function SvgEnterpriseConnectionsIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      width={43}
      height={43}
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={21.599} cy={21.265} r={20.787} fill="#5851FF" />
      <path
        d="M3.712 26.954L12.6 14.753v12.201H3.712zM30.598 14.756l8.885 12.198h-8.885V14.756zM26.29 34.226c0 4.618-4.69 7.825-4.69 7.825s-4.69-3.042-4.69-7.825c0-3.278 2.099-5.27 4.69-5.27 2.59 0 4.69 1.992 4.69 5.27zM14.67 1.654h6.929a6.929 6.929 0 016.929 6.928v18.372H14.67v-25.3z"
        fill="#fff"
      />
      <path fill="#5851FF" d="M16.909 8.017h9.381v4.5h-9.381zM16.909 14.753h9.381v4.5h-9.381z" />
    </svg>
  )
}

export default SvgEnterpriseConnectionsIcon
