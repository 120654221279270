import React, { useState } from 'react'
import { Link } from 'gatsby'
import Container from '../elements/container'
import CallToAction from '../elements/call_to_action'
import SvgCryptrLogo from '../svgs/cryptr_logo'

import {
  HeaderBackground,
  HeaderCallToActionWrapper,
  HeaderLogo,
  HeaderMenuMobileButton,
  HeaderWrapper,
} from './style'
import HeaderLink from './header_link'
import MobileMenu from './mobile_menu'

type MenuID = null | 'PRODUCTS_MENU' | 'DEVELOPERS_MENU' | 'RESOURCES_MENU'

const Header = ({ navbarData, children, onMouseLeave }: any) => {
  const [isMenuMobileOpen, setMenuMobileOpen] = useState(false)
  const [currentMenuId, setCurrentMenuId] = useState<MenuID>(null)
  const productItems = navbarData.filter(
    (x: any) => x.__typename === 'PrismicLayoutNavNavItemProduct',
  )

  return (
    <HeaderBackground>
      <Container>
        <HeaderWrapper onMouseLeave={() => setCurrentMenuId(null)}>
          <HeaderLogo>
            <Link aria-label="Cryptr Home page" to="/">
              <SvgCryptrLogo />
            </Link>
          </HeaderLogo>
          <HeaderMenuMobileButton>
            <button
              aria-label="Menu mobile"
              onClick={() => setMenuMobileOpen(!isMenuMobileOpen)}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </HeaderMenuMobileButton>
          <HeaderLink
            isMenuProductsOpen={currentMenuId === 'PRODUCTS_MENU'}
            isMenuDevelopersOpen={currentMenuId === 'DEVELOPERS_MENU'}
            isMenuResourcesOpen={currentMenuId === 'RESOURCES_MENU'}
            setCurrentMenuId={setCurrentMenuId}
            productItems={productItems}
            navbarData={navbarData}
          >
            {children}
          </HeaderLink>
          <HeaderCallToActionWrapper>
            <CallToAction>
              {/* <a className="white main" href={navbarData[0].items[0].call_to_action_path}>
                {navbarData[0].items[0].call_to_action_text}
              </a> */}
              <a className="color main" href={navbarData[0].items[1].call_to_action_path}>
                {navbarData[0].items[1].call_to_action_text}
              </a>
            </CallToAction>
          </HeaderCallToActionWrapper>
        </HeaderWrapper>
      </Container>
      {isMenuMobileOpen && (
        <MobileMenu
          productItems={productItems}
          navbarData={navbarData}
          setMenuMobileOpen={setMenuMobileOpen}
        />
      )}
    </HeaderBackground>
  )
}

export default Header
