import styled from '@emotion/styled'
import tw from 'twin.macro'

export const MobileMenuWrapper = styled.div`
  ${tw`
    absolute
    top-0
    inset-x-0
    p-2
    transition
    transform
    origin-top-right
    md:hidden
  `}
`
export const MobileMenuCard = styled.div`
  ${tw`
    rounded-lg
    shadow-lg
  `}
  .card-background {
    ${tw`
      rounded-lg
      shadow-sm
      bg-white
      divide-y-2
      divide-gray-100
    `}
  }
  .card-padding {
    ${tw`
      pt-5
      pb-6
      px-5
      space-y-6
    `}
  }
`

export const MobileMenuLogo = styled.div`
  svg {
    ${tw`
      h-6
      w-auto
    `}
  }
`

export const MobileMenuIcon = styled.div`
  ${tw`
    -mr-2
  `}
  button {
    ${tw`
      inline-flex
      items-center
      justify-center
      p-2
      rounded-md
      text-gray-400
      hover:text-gray-500
      hover:bg-gray-100
      focus:outline-none
      focus:bg-gray-100
      focus:text-gray-500
      transition
      duration-150
      ease-in-out
    `}
    svg {
      ${tw`
        h-6
        w-6
      `}
    }
  }
`

export const MobileMenuHeader = styled.div`
  ${tw`
    flex
    items-center
    justify-between
  `}
`

export const MobileMenuProducts = styled.div`
  nav {
    ${tw`
      grid
      gap-6
    `}
  }
`

export const MobileMenuProductsItem = styled.div`
  a {
    ${tw`
      -m-3
      flex
      items-center
      space-x-4
      rounded-lg
      hover:bg-gray-100
      transition
      ease-in-out
      duration-150
    `}
  }
  .nav-link-icon {
    ${tw`
      flex-shrink-0
      flex
      items-center
      justify-center
      h-10
      w-10
    `}
    svg {
      ${tw`
        h-4
        w-4
      `}
    }
  }
  .nav-link-title {
    ${tw`
      ml-0
      text-sm
      leading-6
      font-medium
      text-purple-900
    `}
  }
`

export const MobileMenuNavItem = styled.div`
  ${tw`
    py-6
    px-5
    space-y-6
  `}
`

export const MobileMenuNavItemGrid = styled.div`
  ${tw`
    grid
    grid-cols-2
    gap-4
  `}
`

export const MobileMenuLink = styled.div`
  ${tw`
    text-sm
    leading-6
    font-medium
    text-gray-500
    hover:text-gray-700
    transition
    ease-in-out
    duration-150
  `}
`

export const MobileMenuCallToAction = styled.div`
  ${tw`
    space-y-6
    flex
    flex-wrap
    bg-purple-100
    rounded-md
    py-6
    px-6
  `}
`

export const MobileMenuCtaEventPromotion = styled.span`
  ${tw`
    w-full
    flex
    rounded-full
  `}
  a {
    ${tw`
      w-full
      flex
      items-center
      justify-center
      px-4
      py-2
      border
      border-transparent
      text-base
      leading-6
      font-medium
      rounded-full
      text-white
      bg-purple-700
      hover:bg-purple-800
      focus:outline-none
      focus:border-purple-800
      focus:shadow-outline-purple
      active:bg-purple-800
      transition
      ease-in-out
      duration-150
    `}
  }
`

export const MobileMenuCtaProductDiscovery = styled.p`
  ${tw`
    w-full
    text-center
    text-sm
    leading-6
    font-medium
    text-gray-500
  `}
  a {
    ${tw`
      text-purple-700
      hover:text-purple-600
      transition
      ease-in-out
      duration-150
    `}
  }
`
