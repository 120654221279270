import styled from '@emotion/styled'
import tw from 'twin.macro'

export const FooterBackground = styled.footer`
  ${tw`
    bg-purple-100
  `}
`

export const FooterWrapper = styled.div`
  ${tw`
    py-12
    lg:py-16
  `}
`

export const FooterGrid = styled.div`
  ${tw`
    xl:grid
    xl:grid-cols-3
    xl:gap-8
  `}
`

export const FooterCompany = styled.div`
  ${tw`
    space-y-8
    xl:col-span-1
  `}
  svg {
    ${tw`
      h-8
    `}
  }
  p {
    ${tw`
      text-gray-500
      text-sm
      leading-6
    `}
  }
`

export const FooterSocialLinksWrapper = styled.div`
  ${tw`
    flex
    space-x-6
  `}
`

export const FooterSocialLink = styled.a`
  ${tw`
    text-gray-400
    cursor-pointer
    hover:text-gray-500
  `}
  span {
    ${tw`
      sr-only
    `}
  }
  svg {
    ${tw`
      h-6
      w-6
    `}
  }
`

export const FooterColsGrid = styled.div`
  ${tw`
    mt-12
    grid
    grid-cols-2
    md:grid-cols-3
    gap-8
    xl:mt-0
    xl:col-span-2
  `}
`

// export const FooterColsGrid = styled.div`
//   ${tw`
//     md:grid
//     md:grid-cols-3
//     md:gap-8
//   `}
// `

export const FooterCol = styled.div`
  ${tw`
    mt-12
    md:mt-0
  `}
  h1 {
    ${tw`
      text-sm
      leading-5
      font-semibold
      text-purple-900
      tracking-wider
      uppercase
    `}
  }
  ul {
    ${tw`
      mt-4
      space-y-4
    `}
  }
  a {
    ${tw`
      text-sm
      leading-6
      capitalize
      cursor-pointer
      text-gray-500
      hover:text-gray-900
    `}
  }
`

export const FooterCopyright = styled.div`
  ${tw`
    mt-12
    border-t
    border-green-500
    pt-8
  `}
  p {
    ${tw`
      text-sm
      leading-6
      text-gray-400
      xl:text-center
      px-4 
    `}
  }
`

export const FooterLink = styled.div`
  ${tw`
    text-sm
    leading-6
    cursor-pointer
    text-gray-500
    hover:text-gray-900
    px-4 
  `}
`

export const FooterRow = styled.div`
  ${tw`
    flex
    flex-row
    justify-center
  `}
`
