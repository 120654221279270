/** @jsx jsx */

import styled from '@emotion/styled'
import tw from 'twin.macro'

const CallToAction = styled.span`
  ${tw`
    flex
    flex-row
    items-center
    rounded-full
    space-x-6
  `}
  svg {
    ${tw`
      h-4
      w-4
      ml-1
    `}
  }
  white-space: nowrap;
  a {
    ${tw`
      inline-flex
      items-center
      justify-center
      px-4
      py-1
      border
      border-transparent
      text-xs
      leading-6
      font-medium
      rounded-full
      transition
      ease-in-out
      duration-150
    `}
    &.white.main,
    &.white.sso-admin-onboarding {
      ${tw`
        bg-purple-100
        text-purple-700
        hover:bg-white
      `}
    }
    &.color.main,
    &.color.sso-admin-onboarding {
      ${tw`
        text-white
        bg-purple-700
        hover:bg-purple-600
      `}
    }
    &.color.home {
      ${tw`
        text-white
        bg-teal-500
        hover:bg-teal-600
      `}
    }
    &.white.main,
    &.white.users-management,
    &.white.authentication,
    &.white.scim {
      ${tw`
        bg-white
        shadow-lg
        hover:shadow-xl
      `}
    }
    &.white.home {
      ${tw`
        bg-white
        text-teal-500
        hover:bg-teal-200
      `}
    }
    &.white.authentication,
    &.white.developer,
    &.white.scim {
      ${tw`
        text-blue-400
        hover:bg-blue-100
      `}
    }
    &.color.authentication,
    &.color.developer,
    &.color.scim {
      ${tw`
        text-white
        bg-blue-400
        hover:bg-blue-500
      `}
    }
    &.white.users-management {
      ${tw`
        text-green-500
        hover:bg-green-200
      `}
    }
    &.color.users-management {
      ${tw`
        text-white
        bg-green-500
        hover:bg-green-600
      `}
    }
    &.white.pro {
      ${tw`
        bg-white
        text-purple-500
        hover:bg-purple-200
      `}
    }
    &.color.pro {
      ${tw`
        text-white
        bg-purple-500
        hover:bg-purple-600
      `}
    }
    &.white.pricing {
      ${tw`
        bg-white
        text-pink-500
        hover:bg-pink-200
      `}
    }
    &.color.pricing {
      ${tw`
        text-white
        bg-pink-500
        hover:bg-pink-600
      `}
    }
    &.white.ui-editor {
      ${tw`
        bg-white
        shadow-lg
        hover:shadow-xl
      `}
      :hover {
        background-color: #e8dbff;
      }
      color: #7126f2;
    }
    &.color.ui-editor {
      ${tw`
        text-white
        shadow-lg
        hover:shadow-xl
      `}
      :hover {
        background-color: #975ff8;
      }
      background-color: #7126f2;
    }
    &.white.enterprise-connections {
      ${tw`
        bg-white
        shadow-lg
        hover:shadow-xl
      `}
      :hover {
        background-color: #eae9ff;
      }
      color: #5851ff;
    }
    &.color.enterprise-connections {
      ${tw`
        text-white
        shadow-lg
        hover:shadow-xl
      `}
      background-color: #5851FF;
      :hover {
        background-color: #817bff;
      }
    }
  }
`
export default CallToAction
